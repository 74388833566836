document.addEventListener("DOMContentLoaded", () => {
    const hamburgerBtns = document.querySelectorAll(".hamburger"); // Select all hamburger buttons
    const siteNavs = document.querySelectorAll(".site-nav"); // Select all navigation menus
    const siteHeaders = document.querySelectorAll(".site-header"); // Select all headers

    // Toggle Mobile Navigation for Each Hamburger Button
    hamburgerBtns.forEach((hamburgerBtn, index) => {
        hamburgerBtn.addEventListener("click", () => {
            const expanded = hamburgerBtn.getAttribute("aria-expanded") === "true" || false;
            hamburgerBtn.setAttribute("aria-expanded", !expanded);

            // Toggle only the corresponding navigation menu
            if (siteNavs[index]) {
                siteNavs[index].classList.toggle("nav-open");
            }
        });
    });

    // Close navigation when clicking a link or outside
    document.addEventListener("click", (e) => {
        siteNavs.forEach((nav, index) => {
            const isClickInsideNav = nav.contains(e.target);
            const isClickOnHamburger = hamburgerBtns[index].contains(e.target);

            if (!isClickInsideNav && !isClickOnHamburger) {
                nav.classList.remove("nav-open");
                hamburgerBtns[index].setAttribute("aria-expanded", "false");
            }
        });
    });

    // Close navigation on clicking a nav link
    siteNavs.forEach((nav, index) => {
        const navLinks = nav.querySelectorAll("a"); // Select all links within the nav
        navLinks.forEach((link) => {
            link.addEventListener("click", () => {
                nav.classList.remove("nav-open");
                hamburgerBtns[index].setAttribute("aria-expanded", "false");
            });
        });
    });

    // Change Header on Scroll for All Headers
    window.addEventListener("scroll", () => {
        siteHeaders.forEach(header => {
            if (window.scrollY > 50) {
                header.setAttribute("data-scrolled", "true");
            } else {
                header.removeAttribute("data-scrolled");
            }
        });
    });
});